export const enum JOB_STATUS {
    Processing = 'processing', // The system is currently in the process of generating subtitles.
    Assigned = 'assigned', // Assignment request, a contributor is currently working on it.
    ReadyForAudit = 'ready_for_audit', // The request is ready for audit after the contributor has worked on it.
    Rejected = 'rejected', // The request has been rejected; you can republish it, and the next contributor will see the latest available version.
    Ready = 'ready', // Ready for contributor assignment.
    Completed = 'completed', // The request is ready to be delivered to the requesting platform.
    Delivered = 'delivered', // The request is delivered to the requesting platform.
    Fail = 'fail' // Generated subtitles failed
}

export const JOB_STATUSES = [
    {
        value: JOB_STATUS.Processing,
        label: 'job_status:processing'
    },
    {
        value: JOB_STATUS.Assigned,
        label: 'job_status:assigned'
    },
    {
        value: JOB_STATUS.ReadyForAudit,
        label: 'job_status:ready_for_audit'
    },
    {
        value: JOB_STATUS.Rejected,
        label: 'job_status:rejected'
    },
    {
        value: JOB_STATUS.Completed,
        label: 'job_status:completed'
    },
    {
        value: JOB_STATUS.Delivered,
        label: 'job_status:delivered'
    },
    {
        value: JOB_STATUS.Ready,
        label: 'job_status:ready'
    },
    {
        value: JOB_STATUS.Fail,
        label: 'job_status:fail'
    }
]

export const enum VIDEO_STATUS {
    Ready = 'ready',
    Completed = 'completed',
    ReadyForAudit = 'ready_for_audit',
    Assigned = 'assigned',
    Rejected = 'rejected',
    Delivered = 'delivered'
}

export const DURATION = {
    short: {name: 'short', value: 60000}, // 1 min in milliseconds
    medium: {name: 'medium', value: 180000}, // 3 min in milliseconds
    long: {name: 'long', value: 900000} // 15 min in milliseconds
}

export const SORT_BY = {
    duration: 'duration_in_seconds',
    name: 'name'
}

export const MESSAGE_LANGUAGES_CODES_A = {
    from: ['de', 'el', 'es', 'it', 'nl', 'ro', 'sl'],
    to: 'en'
}

export const MESSAGE_LANGUAGES_CODES_B = {
    from: 'en',
    to: ['de', 'es', 'it', 'nl', 'ro', 'en']
}
